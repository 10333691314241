import './static/variables.css'
import './index.css'

// comment on build
// import "preact/debug"

import App from './pages/App'

// import { render } from 'preact/compat'
// render(<App />, document.body)

export default App
